.project {
  height: 100%;
  border: 2px solid rgba(141, 141, 141, 0.4) !important;
  background-color: inherit !important;
  text-decoration: none !important;
  padding-top: 0.6em;
  padding-bottom: 1.5em;
}

.project:hover {
  border: 2px solid transparent !important;
  background-color: rgba(141, 141, 141, 0.4) !important;
}

.project .card-text {
  color: white !important;
}

.project-work {
  color: rgb(154, 255, 199) !important;
}

.project-personal {
  color: rgb(255, 208, 154) !important;
}
