body {
  font-family:
    muli,
    'Segoe UI',
    Tahoma,
    Geneva,
    Verdana,
    sans-serif;
  color: white;
  background-image: url('https://images.pexels.com/photos/2763927/pexels-photo-2763927.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root,
.wrapper,
.content {
  min-height: 100vh;
}

.navbar-column {
  -ms-flex: 0 0 400px;
  flex: 0 0 400px;
}

@media (max-width: 1000px) {
  .navbar-column {
    display: none;
  }
}

.home-section:not(:nth-child(1)) {
  margin-top: 50px;
}

.home-section-title {
  margin-bottom: 1em;
}

.home-section-subtitle {
  display: inline-block;
  font-size: 21px;
  max-width: 50%;
  color: #2c2c2c;
}

.home-intro {
  padding-top: 50px;
  padding-bottom: 50px;
  color: #000;
  background-color: #e4e4e4;
}

.content {
  position: relative;
}

.home-after-intro {
  padding-top: 50px;
  position: absolute;
  background-color: rgba(19, 19, 19, 0.85);
  width: 100%;
  padding-bottom: 200px;
}

.project-row {
  margin-bottom: 2em;
}
